import { FC, useEffect, useMemo, useState } from "react";

import {
  Box,
  Column,
  EmptyState,
  Link,
  LinkButton,
  Paragraph,
  Row,
  SectionHeading,
  Select,
  Spinner,
  Switch,
  TagInput,
  Text,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import add from "date-fns/add";
import { isPresent } from "ts-extras";

import goalsPlaceholder from "src/assets/placeholders/goals.svg";
import {
  useDisableAudienceGoalsForAudienceMutation,
  useEnableAudienceGoalsForAudienceMutation,
  useGoalMetricsQuery,
  useGoalsQuery,
} from "src/graphql";
import { Audience } from "src/types/visual";

import { ActionBar } from "../action-bar";
import { graphColors } from "./colors";
import { PerformanceGraph } from "./performance-graph";

enum TimeOptions {
  OneWeek = "1-week",
  OneMonth = "1-month",
  ThreeMonths = "1-months",
  SixMonths = "6-month",
  OneYear = "1-year",
  All = "all",
}

const timeOptions = [
  { label: "Past 1 week", value: TimeOptions.OneWeek },
  { label: "Past 1 month", value: TimeOptions.OneMonth },
  { label: "Past 3 months", value: TimeOptions.ThreeMonths },
  { label: "Past 6 months", value: TimeOptions.SixMonths },
  { label: "Past 1 year", value: TimeOptions.OneYear },
  { label: "Since tracking began", value: TimeOptions.All },
];

type PerformanceProps = {
  audience: Audience;
  metrics: { name: string; id: string }[];
};

export const Performance: FC<PerformanceProps> = ({ metrics, audience }) => {
  const [selectedMetrics, setSelectedMetrics] = useState<string[]>([]);
  const [timeValue, setTimeValue] = useState<TimeOptions>(TimeOptions.OneWeek);
  const { toast } = useToast();

  const [performanceEnabled, setPerformanceEnabled] = useState(false);

  const since: string | null = useMemo(() => {
    const currentDate = new Date();

    if (timeValue === TimeOptions.All) {
      return null;
    }

    let duration;

    switch (timeValue) {
      case TimeOptions.OneWeek:
        duration = { days: -7 };
        break;
      case TimeOptions.OneMonth:
        duration = { months: -1 };
        break;
      case TimeOptions.ThreeMonths:
        duration = { months: -3 };
        break;
      case TimeOptions.SixMonths:
        duration = { months: -6 };
        break;
      case TimeOptions.OneYear:
        duration = { months: -12 };
        break;
    }

    return add(currentDate, duration).toISOString();
  }, [timeValue]);

  useEffect(() => {
    setSelectedMetrics(metrics.map(({ id }) => id));
  }, [metrics]);

  const metricOptions = metrics.filter(Boolean).map(({ name, id }) => ({ label: name, value: id }));

  // these metrics refer to the metrics that have been enabled on this audience via `audience_goals`
  const noMetrics = metrics.length === 0;
  const metricsSelected = selectedMetrics.length > 0;

  useEffect(() => {
    setPerformanceEnabled(!noMetrics);
  }, [noMetrics]);

  // this query is used to determine whether goals are set up at all for this parent model, if they are not, we should show a placeholder
  const { data: goals, isLoading: isGoalsLoading } = useGoalsQuery(
    { filters: { parent_model_id: { _eq: audience?.parent?.id } } },
    {
      enabled: Boolean(audience?.parent?.id),
      select: (data) => data.goals,
    },
  );

  const hasGoalsSetup = Boolean(goals?.length);

  const { mutateAsync: enableGoals, isLoading: enableLoading } = useEnableAudienceGoalsForAudienceMutation();
  const { mutateAsync: disableGoals, isLoading: disableLoading } = useDisableAudienceGoalsForAudienceMutation();

  const toggleLoading = enableLoading || disableLoading;

  const togglePerformance = async (enabled: boolean): Promise<void> => {
    setPerformanceEnabled(enabled);
    try {
      if (enabled) {
        await enableGoals({ objects: goals?.map((g) => ({ goal_id: g.id, segment_id: audience?.id, enabled: true })) ?? [] });
      } else {
        await disableGoals({ segment_id: audience?.id });
      }
    } catch (error) {
      setPerformanceEnabled(!enabled);
      Sentry.captureException(error);
      toast({
        id: "enable-metrics-toast",
        title: `Error ${enabled ? "enabling" : "disabling"} metrics`,
        message: "Enabling metrics for this audience failed",
        variant: "error",
      });
    }
  };

  const removeMetric = (metric: string) => {
    setSelectedMetrics((prevMetrics) => prevMetrics.filter((currentMetric) => currentMetric !== metric));
  };

  const currentDate = useMemo(() => new Date().toISOString(), []);

  const { data: metricData, isFetching: metricFetching } = useGoalMetricsQuery(
    { filter: { id: { _in: selectedMetrics } }, audienceIds: [audience?.id ?? ""], since, before: currentDate },
    {
      enabled: Boolean(selectedMetrics.length) && isPresent(audience?.id),
      select: (data) => data.goals,
      keepPreviousData: true,
    },
  );

  if (isGoalsLoading) {
    return <Spinner size="lg" m="auto" />;
  }

  if (!hasGoalsSetup) {
    return (
      <EmptyState
        imageUrl={goalsPlaceholder}
        title="No performance data available"
        message={`Performance data requires metrics to be defined on the parent model. To view this audience’s performance, please add a metric to the ${parent.name} parent model first.`}
        actions={
          <LinkButton href="/schema/metrics/new" variant="primary">
            Add metric
          </LinkButton>
        }
      />
    );
  }

  return (
    <Column flex={1} gap={2} mb={16} minHeight={0} width="100%">
      <Column gap={2} maxWidth="586px">
        <Row align="center" gap={4}>
          <SectionHeading>Enable performance tracking</SectionHeading>
          <Switch isChecked={performanceEnabled} onChange={togglePerformance} />
        </Row>
        <Paragraph color="text.secondary">
          View performance of this audience and its split groups against{" "}
          <Link href="/schema/metrics">metrics defined in your schema</Link>.
        </Paragraph>
      </Column>

      {performanceEnabled && (
        <Column flex={1} minHeight={0} gap={4}>
          {toggleLoading && <Spinner size="lg" m="auto" />}
          {noMetrics && !toggleLoading && (
            // this state should not occur right now, because when there are no metrics, the entire performance section is hidden
            <EmptyState
              imageUrl={goalsPlaceholder}
              title="No performance metrics enabled for this audience"
              message="Performance data requires metrics to be enabled for this audience."
            />
          )}

          {!noMetrics && !toggleLoading && (
            <Row justify="space-between" mt={6}>
              <Text fontWeight="medium" size="lg">
                Audience performance
              </Text>
              <Row gap={2}>
                <TagInput
                  width="md"
                  options={metricOptions}
                  value={selectedMetrics}
                  onChange={setSelectedMetrics}
                  placeholder="Select a metric..."
                />
                <Select
                  isLoading={metricFetching}
                  options={timeOptions}
                  width="3xs"
                  value={timeValue}
                  onChange={(value) => setTimeValue(value ?? TimeOptions.OneWeek)}
                />
              </Row>
            </Row>
          )}

          {!noMetrics && !metricsSelected && (
            <EmptyState title="No metrics selected" message="Select a metric above to view audience performance" />
          )}

          {selectedMetrics.map((id) => {
            const metric = metricData?.find((m) => m.id === id);

            return <PerformanceGraph key={id} audience={audience} metric={metric ?? null} onRemove={() => removeMetric(id)} />;
          })}

          {!noMetrics && metricsSelected && Boolean(audience?.splits.length) && (
            <ActionBar>
              <Row as="ul" gap={2} listStyleType="none">
                {audience?.splits.map(({ friendly_name }, index) => (
                  <Box
                    key={index}
                    as="li"
                    bg={graphColors[index % graphColors.length]!.bg}
                    borderRadius="36px"
                    mt={1}
                    px={2}
                    width="fit-content"
                  >
                    <Text color={graphColors[index % graphColors.length]!.color}>{friendly_name}</Text>
                  </Box>
                ))}
              </Row>
            </ActionBar>
          )}
        </Column>
      )}
    </Column>
  );
};
